import { datadogLogs } from '@datadog/browser-logs';
import React, { type ErrorInfo, type ReactNode } from 'react';

export class ErrorBoundary extends React.Component<{ children?: ReactNode }, {}> {
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    datadogLogs.logger.error(error.message, { errorInfo });
  }

  render() {
    return this.props.children;
  }
}
