import { type ClassValue, clsx } from 'clsx';
import { ParseError, parsePhoneNumber, parsePhoneNumberWithError } from 'libphonenumber-js';
import type { CountryCode } from 'libphonenumber-js/max';
import { type IntlShape, type PrimitiveType, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { supportedLocaleMap } from '@/configuration/lang/lang';

import type { CardsCustomization } from '@/features/onboarding/components/ChargingCardsStep';

import type { AdditionalVatSchemaType } from '@/components/VatSchema';

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

export const addressBuilder = (...addresses: string[]): string => {
  if (addresses.length === 0) {
    return '';
  }

  if (addresses.length === 1) {
    return addresses[0];
  }

  return addresses.join(', ');
};

export const checkCustomization = (list?: CardsCustomization[]): boolean => {
  let result = false;
  list?.forEach((c) => {
    if ((c.line1 && c.line1 !== '') || (c.line2 && c.line2 !== '')) {
      result = true;
    }
  });
  return result;
};

export type PhoneAndCountry = {
  number: string;
  country: string;
};

export const parseCountryAndPhoneNumber = (phone?: string): PhoneAndCountry | undefined => {
  if (phone) {
    const phoneNumber = parsePhoneNumber(phone);
    if (phoneNumber) {
      return {
        number: phoneNumber.nationalNumber,
        country: phoneNumber.country ?? '',
      };
    }
  }
  return undefined;
};

export const buildPhoneNumber = (countryCode: string, phoneNumber: string) => {
  const phone = parsePhoneNumber(phoneNumber, countryCode as CountryCode);
  if (phone?.isValid()) {
    return phone.number;
  }

  return '';
};

export const phoneNumberValidity = (countryCode: string, phoneNumber: string): boolean => {
  try {
    const parsed = parsePhoneNumberWithError(phoneNumber, {
      defaultCountry: countryCode as CountryCode,
      extract: false,
    });
    if (!parsed) {
      return false;
    }
    if (parsed.country !== (countryCode as CountryCode)) {
      return false;
    }
    return parsed.isValid();
  } catch (error) {
    if (error instanceof ParseError) {
      console.error(error.message);
    } else {
      throw error;
    }
  }
  return false;
};

export const getVatCountries = (
  countryCode: string,
  additional: AdditionalVatSchemaType,
  country: string,
): string[] => {
  const result = additional.map((c) => {
    if (c.country !== countryCode) {
      return c.country;
    }

    return '';
  });
  return [country, ...result];
};

export const includesAny = <T>(arr: T[] | undefined | null, values: T[] | T | undefined | null): boolean => {
  if (!arr || !values) {
    return false;
  }

  if (!Array.isArray(values)) {
    return arr?.includes(values);
  }

  return values.some((v) => arr.includes(v));
};

//  Intl helper function to "mimic" nextjs solution
export const t = (id: string, values?: Record<string, PrimitiveType>, intl?: IntlShape) => {
  if (intl) {
    return intl.formatMessage({ id }, values);
  }
  return useIntl().formatMessage({ id }, values);
};

export const stringifyQueryParams = (params: Object) => {
  return Object.entries(params)
    .filter(([_, value]) => value !== undefined && value !== '')
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
};

export const formatEnergyValue = (
  value: number,
  unit = 'kWh',
  decimalPlaces = 2,
  roundingMode: 'ceil' | 'floor' | 'trunc' | 'halfEven' = 'halfEven',
): { value: string; unit: string } => {
  // The German-only number format (de-DE) is being added.
  const formattedValue = new Intl.NumberFormat(supportedLocaleMap.de, {
    style: 'decimal',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
    roundingMode,
  });

  return {
    value: formattedValue.format(value),
    unit,
  };
};

export const formatCurrenyValue = (
  value: number,
  currency = 'EUR',
  decimalPlaces = 2,
  roundingMode: 'ceil' | 'floor' | 'trunc' | 'halfEven' = 'halfEven',
) => {
  // The German-only number format (de-DE) is being added.
  const formattedValue = new Intl.NumberFormat(supportedLocaleMap.de, {
    style: 'currency',
    currency,
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
    roundingMode,
  } as Intl.NumberFormatOptions);

  return {
    value: formattedValue.format(value),
    unit: currency,
  };
};

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const parseStaffId = (rioId: string): string => {
  const parts = rioId.split('prod-rio-users:');
  return parts.length > 1 ? parts[1] : rioId;
};
