import { Dialog, type DialogProps, SplitDialog, type SplitDialogProps } from '@rio-cloud/rio-uikit';
import { type ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';

type OpenModal<T> = (params: T) => void;

export enum DialogType {
  SPLIT = 'SPLIT',
  SIMPLE = 'SIMPLE',
}

type DialogModalPropsType = {
  type: DialogType;
} & DialogProps &
  SplitDialogProps;

interface IModalContext {
  openDialog: OpenModal<DialogModalPropsType>;
  closeDialog: () => void;
}

const ModalContext = createContext<IModalContext>({} as IModalContext);

export const useModal = () => useContext(ModalContext);

export const ModalContextProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const [dialogProps, setDialogProps] = useState<DialogModalPropsType | null>(null);
  const [isVisible, setIsVisible] = useState(false);

  const openDialog = useCallback((props: DialogModalPropsType) => {
    setDialogProps(props);
    setTimeout(() => {
      setIsVisible(true);
    }, 50);
  }, []);

  const closeDialog = useCallback(() => {
    setIsVisible(false);
  }, []);

  useEffect(() => {
    if (!isVisible && dialogProps) {
      const timer = setTimeout(() => {
        setDialogProps(null);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [isVisible, dialogProps]);

  const modalContextValue: IModalContext = {
    openDialog,
    closeDialog,
  };

  const renderDialog = () => {
    if (!dialogProps) {
      return null;
    }

    const commonProps = {
      ...dialogProps,
      show: isVisible,
      onClose: () => {
        closeDialog();
        if (dialogProps.onClose) {
          dialogProps.onClose();
        }
      },
    };

    switch (dialogProps.type) {
      case DialogType.SPLIT:
        return <SplitDialog {...commonProps} />;
      case DialogType.SIMPLE:
        return <Dialog {...commonProps} />;
      default:
        return null;
    }
  };

  return (
    <ModalContext.Provider value={modalContextValue}>
      {renderDialog()}
      {children}
    </ModalContext.Provider>
  );
};
