import { Button } from '@rio-cloud/rio-uikit';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import type { CustomStepType, NavigationProps } from '@/features/guidedTour/types';

import { cn } from '@/lib/utils';
import { trackTourStep } from '../utils/analytics';

const NAVIGATION_DELAY = 50;

export const CustomNavigation = ({ steps, currentStep, setCurrentStep }: NavigationProps) => {
  const navigate = useNavigate();

  const [isNavigating, setIsNavigating] = useState(false);

  const stepsLength = steps.length;

  const saveStep = useCallback(
    (step: number, navigationMethod: 'next' | 'prev' | 'dot') => {
      localStorage.setItem('lastStep', step.toString());

      trackTourStep(step, stepsLength, (steps[step] as CustomStepType).key, navigationMethod);
    },
    [steps, stepsLength],
  );

  const handleNavigation = (step: number, navigationMethod: 'next' | 'prev' | 'dot') => {
    setIsNavigating(true);
    saveStep(step, navigationMethod);
    navigate((steps[step] as CustomStepType).route);

    setTimeout(() => {
      setCurrentStep(step);
      setIsNavigating(false);
    }, NAVIGATION_DELAY);
  };

  const handlePrev = () => {
    if (currentStep > 0 && !isNavigating) {
      handleNavigation(currentStep - 1, 'prev');
    }
  };

  const handleNext = () => {
    if (currentStep < stepsLength - 1 && !isNavigating) {
      handleNavigation(currentStep + 1, 'next');
    }
  };

  return (
    <div className="flex justify-between mt-4">
      <Button
        aria-label="prev button"
        onClick={handlePrev}
        disabled={currentStep === 0}
        style={{
          visibility: currentStep === 0 ? 'hidden' : 'visible',
        }}
        className={cn('btn-muted', currentStep === 0 ? 'text-color-gray' : 'text-color-black')}>
        <i className="rioglyph rioglyph-arrow-left " />
      </Button>
      <ul className="flex items-center flex-wrap p-0 m-0">
        {Array.from({ length: stepsLength }, (_, i) => i).map((index) => {
          return (
            <li
              className={cn(
                'block',
                'mx-0.5',
                'w-8',
                'h-4 bg-transparent rounded-full whitespace-nowrap indent-[100%] overflow-hidden hover:cursor-pointer',
                index === currentStep ? 'bg-secondary' : 'bg-lighter',
              )}
              onClick={() => handleNavigation(index, 'dot')}
              key={`navigation_dot_${index}`}
              aria-label={`Go to step ${index + 1}`}
            />
          );
        })}
      </ul>
      <Button
        aria-label="next button"
        onClick={handleNext}
        disabled={currentStep === stepsLength - 1}
        style={{
          visibility: currentStep === stepsLength - 1 ? 'hidden' : 'visible',
        }}
        className={cn('btn-muted', currentStep === stepsLength - 1 ? 'text-color-gray' : 'text-color-black')}>
        <i className="rioglyph rioglyph-arrow-right " />
      </Button>
    </div>
  );
};
