import { datadogLogs } from '@datadog/browser-logs';

interface LogParams {
  message: string;
  error?: Error;
  context?: Record<string, unknown>;
}

const sensitiveKeys = ['authorization'];

const sanitizeData = (data: Record<string, unknown>): Record<string, unknown> => {
  const sanitized: Record<string, unknown> = {};
  for (const [key, value] of Object.entries(data)) {
    if (typeof value === 'object' && value !== null) {
      sanitized[key] = sanitizeData(value as Record<string, unknown>);
    } else if (sensitiveKeys.some((sensitiveKey) => key.toLowerCase().includes(sensitiveKey))) {
      sanitized[key] = null;
    } else {
      sanitized[key] = value;
    }
  }
  return sanitized;
};

const sanitizeError = (error: Error): Record<string, unknown> => {
  const { name, message, stack } = error;
  return sanitizeData({ name, message, stack });
};

export const logError = ({ message, error, context = {} }: LogParams): void => {
  const sanitizedContext = sanitizeData(context);
  const sanitizedError = error ? sanitizeError(error) : undefined;

  console.error(message, { error: sanitizedError, ...sanitizedContext });
  datadogLogs.logger.error(message, {
    error: sanitizedError,
    ...sanitizedContext,
  });
};

export const logInfo = ({ message, context = {} }: Omit<LogParams, 'error'>): void => {
  const sanitizedContext = sanitizeData(context);
  console.info(message, sanitizedContext);
  datadogLogs.logger.info(message, sanitizedContext);
};

export const logWarn = ({ message, context = {} }: Omit<LogParams, 'error'>): void => {
  const sanitizedContext = sanitizeData(context);
  console.warn(message, sanitizedContext);
  datadogLogs.logger.warn(message, sanitizedContext);
};
