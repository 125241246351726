import { type UseQueryOptions, useQuery } from '@tanstack/react-query';
import type { ZodType } from 'zod';

import { createValidationError, handleApiError } from '@/lib/api/errors';
import type { ApiErrorResponse } from '@/lib/api/types';

interface ApiQueryOptions<TData> {
  queryKey: readonly unknown[];
  fetcher: () => Promise<unknown>;
  validator?: ZodType<TData>;
  queryOptions?: Omit<UseQueryOptions<TData, ApiErrorResponse, TData>, 'queryKey' | 'queryFn'>;
}

export const useApiQuery = <TData>({ queryKey, fetcher, validator, queryOptions }: ApiQueryOptions<TData>) => {
  return useQuery<TData, ApiErrorResponse>({
    queryKey,
    queryFn: async () => {
      try {
        const data = await fetcher();

        if (validator) {
          const result = validator.safeParse(data);

          if (!result.success) {
            throw createValidationError('Invalid response data', result.error);
          }

          return result.data;
        }

        return data as TData;
      } catch (error) {
        throw handleApiError(error, { queryKey });
      }
    },
    ...queryOptions,
  });
};
