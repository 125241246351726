import { useLocaleStore } from '@/stores/localeStore';

import { DEFAULT_LOCALE, getSupportedLocale as defaultGetSupportedLocale } from '@/configuration/lang/lang';
import { trace } from '@/configuration/setup/trace';

import { logError, logWarn } from '@/lib/logger';

export const importDisplayMessages = async (locale: string) =>
  (await import(`@/features/translations/${locale}.json`)).default;

export const configureFetchDisplayMessages =
  (fetchDisplayMessages = importDisplayMessages, getSupportedLocale = defaultGetSupportedLocale) =>
  async (locale: string) => {
    const { localeChanged, displayMessagesFetched } = useLocaleStore.getState();
    if (!locale) {
      console.warn('No "locale" supplied when fetching display messages!');
      logWarn({
        message: 'No "locale" supplied when fetching display messages!',
      });
      return Promise.reject();
    }
    const supportedLocale = getSupportedLocale(locale);
    try {
      const displayMessages = await fetchDisplayMessages(supportedLocale);
      trace(`Display messages fetched for "${supportedLocale}"`);
      displayMessagesFetched({ locale: supportedLocale, displayMessages });
    } catch (error: unknown) {
      logError({
        message: `Display messages for "${supportedLocale}" could not be fetched.`,
        error: error as Error,
      });
      localeChanged(DEFAULT_LOCALE);
    }
  };
