import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { router } from '@/routes/Router';

import { config } from '@/config';
import { main } from '@/configuration';
import { handleLoginRedirect } from '@/configuration/login/redirect';

import { ErrorBoundary } from '@/components/ErrorBoundary';

import '@/index.css';

const createQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 3,
      },
    },
  });

const renderApplication = () => {
  const queryClient = createQueryClient();

  createRoot(document.getElementById('root') as HTMLElement).render(
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        {import.meta.env.DEV && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    </ErrorBoundary>,
  );
};

if (window.location.href.startsWith(config.login.redirectUri as string)) {
  handleLoginRedirect();
} else {
  main(renderApplication);
}
