import type { UserManager } from 'oidc-client-ts';

import { config } from '@/config';
import * as storage from '@/configuration/login/storage';
import { trace } from '@/configuration/setup/trace';

const saveCurrentRoute = () => {
  const initialRoute = [window.location.hash, window.location.search].join('').replace(/^#\/?/u, '');
  storage.routeStorage.saveRoute(initialRoute);
  trace('saving initial route', initialRoute);
};

export const attemptInitialSignIn = async (userManager: UserManager) => {
  const isFreshRedirect = window.location.href.includes('redirected');

  try {
    const user = await userManager.signinSilent();
    const initialRoute = storage.routeStorage.getRoute();

    trace('initialRoute lookup', initialRoute);

    if (isFreshRedirect) {
      if (initialRoute) {
        trace(`Go to location "/${initialRoute}"`);
        window.location.replace(`#/${initialRoute}`);
      } else {
        trace('Go to location "/"');
        window.location.replace('/');
      }
    }

    storage.routeStorage.discardRoute();
    return await Promise.resolve(user);
  } catch (error) {
    trace('oidc.signinSilent failed, trying page redirect...', error);

    if (config.login.preventRedirect) {
      console.warn('[feature/login] redirect prevented due to config. Error was', error);
    } else if (isFreshRedirect) {
      trace('oidc.signinSilent.error', 'redirect prevented due to supsicious signin error', error);
      storage.routeStorage.discardRoute();
    } else {
      saveCurrentRoute();
      userManager.signinRedirect();
    }

    trace('auth problem?', error);
    throw new Error('Need to sign in');
  }
};
