import type { GeneralFeedbackCreateType, QuickFeedbackCreateType } from '@/api/schema';
import { useApiMutation } from '@/hooks/useApiMutation';
import { axiosInstance } from '@/lib/api/axios';

const createQuickFeedback = async (data: QuickFeedbackCreateType): Promise<void> => {
  const { data: response } = await axiosInstance.post('/feedback/quick-feedback', data);
  return response;
};

export const useCreateQuickFeedback = () => {
  return useApiMutation({
    mutationFn: createQuickFeedback,
  });
};

const createGeneralFeedback = async (data: GeneralFeedbackCreateType): Promise<void> => {
  const { data: response } = await axiosInstance.post('/feedback', data);
  return response;
};

export const useCreateGeneralFeedback = () => {
  return useApiMutation({
    mutationFn: createGeneralFeedback,
  });
};
