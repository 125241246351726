import ErrorState from '@rio-cloud/rio-uikit/lib/es/ErrorState';
import { useIntl } from 'react-intl';

export type InternalErrorStateProps = {
  reloadTriggerFunction?: () => void;
  additionalClassName?: string;
  innerClassName?: string;
  fullWidth?: boolean;
};

const InternalErrorState = (props: InternalErrorStateProps) => {
  const { reloadTriggerFunction, additionalClassName, innerClassName, fullWidth } = props;
  const intl = useIntl();
  return (
    <ErrorState
      headline={intl.formatMessage({ id: 'general.somethingWentWrong.title' })}
      message={intl.formatMessage({ id: 'general.error.message' }, { linebreak: <br /> })}
      buttons={[
        {
          text: intl.formatMessage({ id: 'general.tryAgain' }),
          onClick: reloadTriggerFunction,
        },
      ]}
      className={`display-flex justify-content-center max-width-100pct ${additionalClassName || ''}`}
      innerClassName={innerClassName}
      fullWidth={fullWidth}
    />
  );
};

export default InternalErrorState;
