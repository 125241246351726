import { createDebugConfig, trackCustomEvent } from '@/configuration/setup/googleAnalytics';

type TourSource = 'initial' | 'manual';

const trackTourEvent = (eventName: string, additionalData: Record<string, any> = {}) => {
  const debugConfig = createDebugConfig();

  trackCustomEvent(
    eventName,
    {
      flow_name: 'guided_tour',
      ...additionalData,
    },
    debugConfig,
  );
};

export const trackTourStart = (source: TourSource) => {
  trackTourEvent('tour_start', {
    trigger: 'click',
    element_name: 'start_button',
    element_state: 'active',
    tour_source: source,
  });
};

export const trackTourDecline = (action: 'skip' | 'close') => {
  trackTourEvent('tour_decline', {
    trigger: 'click',
    element_name: `${action}_button`,
    element_state: 'active',
  });
};

export const trackTourStep = (
  stepIndex: number,
  totalSteps: number,
  stepKey: string,
  navigationMethod: 'next' | 'prev' | 'dot',
) => {
  trackTourEvent('tour_step', {
    trigger: 'click',
    element_name: 'navigation',
    step_index: stepIndex,
    step_key: stepKey,
    step_progress: `${stepIndex + 1}/${totalSteps}`,
    navigation_method: navigationMethod,
  });
};

export const trackTourComplete = (timeSpentSeconds: number) => {
  trackTourEvent('tour_complete', {
    trigger: 'completion',
    element_name: 'tour',
    element_state: 'completed',
    time_spent_seconds: timeSpentSeconds,
  });
};

export const trackTourDrop = (lastStepIndex: number, totalSteps: number, timeSpentSeconds: number) => {
  trackTourEvent('tour_drop', {
    trigger: 'exit',
    element_name: 'tour',
    element_state: 'abandoned',
    last_step_index: lastStepIndex,
    completion_percentage: Math.round((lastStepIndex / totalSteps) * 100),
    time_spent_seconds: timeSpentSeconds,
  });
};
