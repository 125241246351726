import { type UseMutationOptions, useMutation } from '@tanstack/react-query';
import type { ZodType } from 'zod';

import { createValidationError, handleApiError } from '@/lib/api/errors';
import type { ApiErrorResponse } from '@/lib/api/types';

interface ApiMutationOptions<TData, TVariables> {
  mutationFn: (variables: TVariables) => Promise<unknown>;
  validator?: ZodType<TData>;
  options?: Omit<UseMutationOptions<TData, ApiErrorResponse, TVariables>, 'mutationFn'>;
}

interface MutationResult<TData, TVariables> {
  mutateAsync: (variables: TVariables) => Promise<TData>;
  isLoading: boolean;
  isError: boolean;
  error: ApiErrorResponse | null;
  reset: () => void;
}

export const useApiMutation = <TData, TVariables>({
  mutationFn,
  validator,
  options,
}: ApiMutationOptions<TData, TVariables>): MutationResult<TData, TVariables> => {
  const mutation = useMutation({
    mutationFn: async (variables) => {
      try {
        const data = await mutationFn(variables);

        if (validator) {
          const result = validator.safeParse(data);
          if (!result.success) {
            return Promise.reject(createValidationError('Invalid response data', result.error));
          }
          return result.data;
        }

        return data as TData;
      } catch (error) {
        throw handleApiError(error);
      }
    },
    ...options,
  });

  return {
    mutateAsync: mutation.mutateAsync,
    isLoading: mutation.isPending,
    isError: mutation.isError,
    error: mutation.error,
    reset: mutation.reset,
  };
};
