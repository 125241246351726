import { type UserProfile, useAccountStore } from '@/stores/accountStore';
import { type AccessToken, useTokenStore } from '@/stores/tokenStore';

import { useApiQuery } from '@/hooks/useApiQuery';

import { AccountDto, type AccountType } from '@/api/schema';

import { axiosInstance } from '@/lib/api/axios';
import { logError } from '@/lib/logger';

// GET: /admin/account - Returns account info, permissions and feature toggles
export const fetchAccount = async (
  accessToken: AccessToken,
  userProfile: UserProfile | null,
): Promise<AccountType | undefined> => {
  if (!userProfile?.account || !accessToken) {
    logError({
      message: 'User profile, account, or access token not found.',
    });
    return undefined;
  }

  try {
    const { data } = await axiosInstance.get('/admin/account', {
      headers: {
        'X-Account-ID': userProfile.account,
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const accountData = AccountDto.safeParse(data);

    if (!accountData.success) {
      throw new Error(`Invalid account data: ${accountData.error.message}`);
    }

    return accountData.data;
  } catch (error) {
    logError({
      message: 'Could not fetch account',
      error: error instanceof Error ? error : new Error(String(error)),
    });
    return undefined;
  }
};

export const useAccount = () => {
  const { userProfile } = useAccountStore();

  return useApiQuery({
    queryKey: ['account', userProfile?.account],
    fetcher: () => fetchAccount(useTokenStore.getState().accessToken, userProfile),
    validator: AccountDto,
    queryOptions: {
      enabled: !!userProfile?.account,
    },
  });
};
