import join from 'lodash/fp/join';
import {
  InMemoryWebStorage,
  type UserProfile as Profile,
  type User,
  UserManager,
  type UserManagerSettings,
  WebStorageStateStore,
} from 'oidc-client-ts';

import type { UserProfile } from '@/stores/accountStore';
import type { AccessToken } from '@/stores/tokenStore';

import { config } from '@/config';
import type { OAuthConfig } from '@/configuration';
import { mapUserProfile } from '@/configuration/login/userProfile';

const RETRY_SIGNIN_TIMEOUT_IN_MS = 30000;

const retrySigninSilent = (oauthConfig: OAuthConfig, userManager: UserManager) => {
  userManager.signinSilent().catch((error: Error) => {
    if (error.message === 'login_required') {
      oauthConfig.onSessionExpired();
    } else {
      setTimeout(() => retrySigninSilent(oauthConfig, userManager), RETRY_SIGNIN_TIMEOUT_IN_MS);
    }
  });
};

export type SessionRenewedResult = {
  accessToken: AccessToken;
  idToken: Profile;
  profile: UserProfile;
  locale: string;
};

export const adaptPublishedInfo = (result: User): SessionRenewedResult => ({
  accessToken: result.access_token,
  idToken: result.profile,
  locale: result.profile?.locale ?? 'en-GB',
  profile: mapUserProfile(result.profile),
});

export const createUserManager = () => {
  const redirectUri = config.login.redirectUri;
  const silentRedirectUri = config.login.silentRedirectUri;

  const settings: UserManagerSettings = {
    authority: `${config.login.authority}`,
    client_id: `${config.login.clientId}`,
    client_secret: config.login.clientSecret ? `${config.login.clientSecret}` : undefined,
    loadUserInfo: false,
    redirect_uri: `${redirectUri}`,
    response_type: 'code',
    scope: join(' ', config.login.oauthScope),
    silent_redirect_uri: `${silentRedirectUri || redirectUri}`,
    includeIdTokenInSilentRenew: false,
    automaticSilentRenew: true,
    monitorSession: true,
    staleStateAgeInSeconds: 600,
    userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
    filterProtocolClaims: false,
  };

  return new UserManager(settings);
};

export const configureUserManager = (oauthConfig: OAuthConfig, userManager: UserManager) => {
  userManager.events.addUserLoaded((user) => {
    oauthConfig.onSessionRenewed(adaptPublishedInfo(user));
  });

  userManager.events.addUserUnloaded(() => {
    oauthConfig.onSessionExpired();
  });

  userManager.events.addSilentRenewError(() => {
    retrySigninSilent(oauthConfig, userManager);
  });

  userManager.events.addUserSignedOut(() => {
    oauthConfig.onSessionExpired();
  });

  return userManager;
};
