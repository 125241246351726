import { datadogLogs } from '@datadog/browser-logs';

export const initDatadog = () => {
  datadogLogs.init({
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    site: import.meta.env.VITE_DATADOG_SITE,
    env: 'prod',
    service: 'man-charge-go-frontend',
    accountName: 'man-emsp-prod',
    team: 'man-team-emsp',
  });
};
