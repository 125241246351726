export const userManualSections = {
  service: {
    welcome: 'welcome',
  },
  functions: {
    overview: 'overview',
    chargingCards: 'chargingCards',
    activateCard: 'activateCard',
    chargingCardAlias: 'chargingCardAlias',
    orderChargingCard: 'orderChargingCard',
    contractInformation: 'contractInformation',
    invoiceDetails: 'invoiceDetails',
    chargingSessions: 'chargingSessions',
    detailChargingSessions: 'detailChargingSessions',
    invoices: 'invoices',
  },
};

export interface IPagesForUserManualDialog {
  keyPage: string;
  imageSrc: string;
  listKeys: string[];
  listSubtitles?: string[];
}

export const pagesForFunctionSectionOfUserManualDialog: IPagesForUserManualDialog[] = [
  {
    keyPage: userManualSections.functions.overview,
    imageSrc: '/overview.webp',
    listKeys: ['description1', 'description2', 'description3'],
  },
  {
    keyPage: userManualSections.functions.chargingCards,
    imageSrc: '/chargingCards.webp',
    listKeys: ['description1', 'description2', 'description3', 'description4', 'description5'],
  },
  {
    keyPage: userManualSections.functions.activateCard,
    imageSrc: '/activateCard.webp',
    listKeys: ['description1', 'description2', 'description3'],
  },
  {
    keyPage: userManualSections.functions.chargingCardAlias,
    imageSrc: '/chargingCardAlias.webp',
    listKeys: ['description1', 'description2', 'description3'],
  },
  {
    keyPage: userManualSections.functions.orderChargingCard,
    imageSrc: '/orderChargingCard.webp',
    listKeys: ['description1', 'description2', 'description3', 'description4'],
  },
  {
    keyPage: userManualSections.functions.contractInformation,
    imageSrc: '/contractInformation.webp',
    listKeys: ['description1', 'description2', 'description3', 'description4'],
  },
  {
    keyPage: userManualSections.functions.invoiceDetails,
    imageSrc: '/invoiceDetails.webp',
    listKeys: ['description1', 'description2', 'description3', 'description4'],
  },
  {
    keyPage: userManualSections.functions.chargingSessions,
    imageSrc: '/chargingSessions.webp',
    listKeys: [
      'description1',
      'description2',
      'description3',
      'description4',
      'description5',
      'description6',
      'description7',
    ],
  },
  {
    keyPage: userManualSections.functions.detailChargingSessions,
    imageSrc: '/detailChargingSessions.webp',
    listKeys: ['description1', 'description2', 'description3'],
  },
  {
    keyPage: userManualSections.functions.invoices,
    imageSrc: '/invoices.webp',
    listKeys: [
      'description1',
      'description2',
      'description3',
      'description4',
      'description5',
      'description6',
      'description7',
    ],
  },
];

export const pagesForServiceSectionOfUserManualDialog: IPagesForUserManualDialog[] = [
  {
    keyPage: userManualSections.service.welcome,
    imageSrc: '/welcome.webp',
    listSubtitles: ['subtitle1', 'subtitle2'],
    listKeys: [
      'subtitle1.description1',
      'subtitle2.description1',
      'subtitle2.description2',
      'subtitle2.description3',
      'subtitle2.description4',
      'subtitle2.description5',
    ],
  },
];
